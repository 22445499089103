import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';


// import './App.css';
import './Reset.css';
import './Style.css';
import './Custom.css';
import "./Sitemap.css";
import ScrollToTop from './components/ScrollToTop.js';
import useDocumentTitle from './components/useDocumentTitle.js';
import { useConfig } from './components/ConfigContext.js';
import CanonicalTag from "./components/CanonicalTag.js";

const Home = React.lazy(() => import('./components/Home.js'));
const AboutUs = React.lazy(() => import('./components/AboutUs.js'));
const Location = React.lazy(() => import('./components/location/Location.js'));
const LocationDetails = React.lazy(() => import('./components/location/LocationDetails.js'));
const LocationDoctorProfile = React.lazy(() => import('./components/location/LocationDoctorProfile.js'));
const Product = React.lazy(() => import('./components/Product.js'));
const Media = React.lazy(() => import('./components/Media.js'));
const ConditionsTreatments = React.lazy(() => import('./components/ConditionsTreatments.js'));
const Careers = React.lazy(() => import('./components/carrier/Careers.js'));
const Search = React.lazy(() => import('./components/Search.js'));
const Blog = React.lazy(() => import('./components/blog/Blog.js'));
const BlogListing = React.lazy(() => import('./components/blog/BlogListing.js'));
const BlogDetails = React.lazy(() => import('./components/blog/BlogDetails.js'));
const DoctorProfile = React.lazy(() => import('./components/blog/DoctorProfile.js'));
const FaqPage = React.lazy(() => import('./components/FaqPage.js'));
const TreatmentPage = React.lazy(() => import('./components/TreatmentPage.js'));
const ContentPage = React.lazy(() => import('./components/ContentPage.js'));
const LocationMduDoctorProfile = React.lazy(() => import('./components/location/LocationsMduDoctorProfile.js'));
const SmileTest = React.lazy(() => import('./components/SmileTest.js'));
const SmileTestNew = React.lazy(() => import('./components/SmileTestNew.js'));
const SmileTestAll = React.lazy(() => import('./components/SmileTestAll.js'));


const LandingPage = React.lazy(() => import('./components/LandingPage.js'));
const LandingPagenew = React.lazy(() => import('./components/LandingPagenew.js'));
const LandingPagenewDynamic = React.lazy(() => import('./components/LandingPagenewDynamic.js'));

const LandingPageBrands = React.lazy(() => import('./components/LandingPageBrands.js'));


const LandingPageDoctor = React.lazy(() => import('./components/LandingPagenew-doctor'));
const LandingPagePulm = React.lazy(() => import('./components/LandingPagePulm'));
const LandingPageMg = React.lazy(() => import('./components/LandingPageMg'));

const LandingPageOtp = React.lazy(() => import('./components/LandingPageOtp.js'));
const Testimonials = React.lazy(() => import('./components/Testimonials.js'));
const SmileAssesmentNew = React.lazy(() => import('./components/SmileAssesmentNew.js'));
const SmileAssesment = React.lazy(() => import('./components/SmileAssesment.js'));
const SmileAssessmentAll = React.lazy(() => import('./components/SmileAssessmentAll.js'));



const Congratulation = React.lazy(() => import('./components/Congratulation.js'));
const Thankyou = React.lazy(() => import('./components/ThankyouPage.js'));
const SitemapPage = React.lazy(() => import('./components/SitemapPage.js'));
const ClearAligners = React.lazy(() => import('./components/static-products/ClearAligners.js'));
const PabblyData= React.lazy(() => import('./components/PabblyData.js'));








// import axiosInterceptor  from './components/axiosInterceptor';

function App() {

  const configData = useConfig();
  useDocumentTitle(configData?.company_name || 'Whistle');

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <CanonicalTag />
        <Suspense fallback={<div></div>}>
        <Routes>
          <Route exact path="/" element={<Home />}></Route>
          <Route exact path="/about-us" element={<AboutUs />}></Route>
          <Route path="/privacy-policy" element={<ContentPage />} />
          <Route path="/refund-policy" element={<ContentPage />}/>
          <Route path="/product-liability-terms" element={<ContentPage />}/>
          <Route path="/product-terms-conditions" element={<ContentPage />}/>

          <Route exact path="/location" element={<Location />}></Route>
          <Route exact path="/search" element={<Search />}></Route>
          <Route exact path="/location/:name" element={<LocationDetails />} />
          <Route exact path="/location-profile/:slug" element={<LocationDoctorProfile />} />
          <Route exact path="/location-MDU-profile/:slug" element={<LocationMduDoctorProfile />} />

          <Route exact path="/:slug" element={<Product/>}></Route>
          <Route exact path="/media" element={<Media />}></Route>
          <Route exact path="/treatment" element={<ConditionsTreatments />}></Route>
          <Route exact path="/careers" element={<Careers/>}></Route>
          <Route exact path="/blog" element={<Blog/>}></Route>
          <Route exact path="/blog/:slug" element={<BlogListing/>}></Route>
          <Route exact path="/blog/:slug/:title" element={<BlogDetails/>}></Route>
          <Route exact path="/blog-detail/:title" element={<BlogDetails/>}></Route>
          <Route exact path="/doctor-profile/:author" element={<DoctorProfile/>}></Route>
          <Route exact path="/faqs" element={<FaqPage />}></Route>
          <Route exact path="/smile-test" element={<SmileTest/>}></Route>
          <Route exact path="/smile-assessment-questionnaire" element={<SmileTestNew/>}></Route>
          <Route exact path="/smile-assessment-all-questionnaire" element={<SmileTestAll/>}></Route>


          <Route exact path="/landing-page" element={<LandingPage/>}></Route>
          <Route exact path="/landing-cost" element={<LandingPagenew/>}></Route>
          <Route exact path="/landing-cost-new" element={<LandingPagenewDynamic/>}></Route>

          <Route exact path="/landing-plum" element={<LandingPagePulm/>}></Route>
          <Route exact path="/landing-1mg" element={<LandingPageMg/>}></Route>
          <Route exact path="/landing-page-brand" element={<LandingPageBrands/>}></Route>


          
          <Route exact path="/landing-doctor" element={<LandingPageDoctor/>}></Route>
         <Route exact path="/testimonials" element={<Testimonials/>}></Route>
          <Route exact path="/smile-assessment-new" element={<SmileAssesmentNew/>}></Route>
          <Route exact path="/smile-assessment" element={<SmileAssesment/>}></Route>
          <Route exact path="/smile-assessment-all" element={<SmileAssessmentAll/>}></Route>


          <Route exact path="/congratulation" element={<Congratulation/>}></Route>
          <Route exact path="/thank-you" element={<Thankyou/>}></Route>
          <Route exact path="/aligners-and-braces-new-otp" element={<LandingPageOtp/>}></Route>
          <Route exact path="/treatment/:treatment_slug" element={<TreatmentPage/>}></Route>
          <Route exact path="/sitemap" element={<SitemapPage/>}></Route>
          <Route exact path="/braces" element={<ClearAligners/>}></Route>
          <Route exact path="/pabbly-data" element={<PabblyData/>}></Route>



        </Routes>
        </Suspense>
      </Router>

    </div>
  );
}

export default App;
